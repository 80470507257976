export default {
  state: {
    tokens: [],
    candidateLabels: [
      'Bandwagon effect',
      'Gamblers fallacy',
      'Sunk cost fallacy',
      'Halo effect',
      'Stereotyping',
      'Hindsight bias',
      'Negative Bias',
      'Overconfidence effect',
      'Groupthink',
      'Outcome bias',
      'Fundamental attribution error',
      'Status quo bias',
      'Authority bias',
      'False consensus effect',
      'Proportionality Bias',
    ],
    tokenPredictions: [],
    biasDetails: {
      Default: {
        short_description: 'No descriptions yet',
        why_it_happens: 'We are working on it.',
        how_to_avoid_it: 'We are working on it.',
        related_biases: 'We are working on it.',
        read_more: 'We are working on it.',
      },
      'Hindsight bias': {
        short_description:
          'The hindsight bias is our tendency to look back at an unpredictable event and think it was easily predictable. It is also called the ‘knew-it-all-along’ effect.',
        why_it_happens:
          'The hindsight bias happens when new information surrounding a past experience changes our recollection of that experience from an original thought into something different.',
        how_to_avoid_it:
          'By mentally reviewing all the potential outcomes, an event will seem less inevitable and foreseeable.',
        related_biases:
          'Regret Aversion, Cognitive Dissonance, Dunning–Kruger Effect',
        read_more: 'https://thedecisionlab.com/biases/hindsight-bias/',
      },
      'Bandwagon effect': {
        short_description:
          'The Bandwagon effect refers to our habit of adopting certain behaviors or beliefs because many other people do the same.',
        why_it_happens:
          'As an idea or belief increases in popularity, we are more likely to adopt it.',
        how_to_avoid_it:
          'While it is impossible to completely rid ourselves of the bandwagon effect, we may be able to counteract our tendency to automatically use social cues as a driving factor when making influential decisions. This can be achieved by first slowing down our decision making process.',
        related_biases: 'Social Norms',
        read_more: 'https://thedecisionlab.com/biases/bandwagon-effect/',
      },
      'Gamblers fallacy': {
        short_description:
          'The gambler’s fallacy describes our belief that the probability of a random event occurring in the future is influenced by previous instances of that type of event.',
        why_it_happens:
          'The Gambler’s fallacy stems from our tendency to assume that if a random event has occurred many times in the past, that it will occur more or less often in the future. We do this for several reasons. One of them is that we don’t like randomness. So, we try to rationalize random events to create an explanation and make them seem predictable.',
        how_to_avoid_it:
          'Thinking through the actual process by which an event occurs may help us realize that certain past events which resemble it don’t really play a role in it unfolding',
        related_biases: 'Bounded Rationality',
        read_more: 'https://thedecisionlab.com/biases/gamblers-fallacy/',
      },
      'Sunk cost fallacy': {
        short_description:
          'The Sunk Cost Fallacy describes our tendency to follow through on an endeavor if we have already invested time, effort or money into it, whether or not the current costs outweigh the benefits.',
        why_it_happens:
          'When we have previously made an investment into a choice, we are likely to feel guilty or regretful if we do not follow-through on that decision',
        how_to_avoid_it:
          'While it is difficult to overcome inherent cognitive fallacies, if we are aware of the sunk cost fallacy, we can try to ensure we are focusing on current and future costs and benefits instead of past commitments. ',
        related_biases: 'Commitment Bias',
        read_more: 'https://thedecisionlab.com/biases/the-sunk-cost-fallacy/',
      },
      'Halo Bias': {
        short_description:
          'The halo effect is a cognitive bias that claims that positive impressions of people, brands, and products in one area positively influence our feelings in another area.',
        why_it_happens:
          'The halo effect occurs because human social perception is a constructive process. When we form impressions of others, we do not rely solely on objective information; instead, we actively construct an image that fits in with what we already know.',
        how_to_avoid_it:
          'To minimize the influence of the bias, one can look to various cognitive debiasing techniques such as slowing down one’s reasoning process',
        related_biases: 'Anchoring Bias',
        read_more: 'https://thedecisionlab.com/biases/halo-effect/',
      },
      'Negative Bias': {
        short_description:
          'The negative bias is our tendency not only to register negative stimuli more readily but also to dwell on these events.',
        why_it_happens:
          'People have less motivation when an incentive is framed as a means to gain something than when the same incentive will help them avoid the loss of something.',
        how_to_avoid_it:
          'Start paying attention to the type of thoughts that run through your mind. After an event takes place, you might find yourself thinking things like “I shouldn’t have done that.” This negative self-talk shapes how you think about yourself and others.',
        related_biases: 'Pessimism bias',
        read_more: 'https://www.verywellmind.com/negative-bias-4589618',
      },
      Stereotyping: {
        short_description:
          'Stereotyping occurs when a person ascribes the collective characteristics associated with a particular group to every member of that group, discounting individual characteristics.',
        why_it_happens:
          'Stereotypes are shared because they are the result of a common environment that stimulates people to react in the same way',
        how_to_avoid_it: 'See People as Individuals',
        related_biases: 'Social Norms',
        read_more: 'https://en.wikipedia.org/wiki/Stereotype',
      },
      Groupthink: {
        short_description:
          'Groupthink occurs within a group of people in which the desire for harmony or conformity in the group results in an irrational or dysfunctional decision-making outcome.',
        why_it_happens:
          'Recent failures: can lead to low self-esteem, resulting in agreement with the group for fear of being seen as wrong',
        how_to_avoid_it:
          'The purpose of group problem solving is mainly to improve decision quality',
        related_biases: '',
        read_more: 'https://en.wikipedia.org/wiki/Groupthink',
      },
      'Overconfidence effect': {
        short_description:
          'The overconfidence effect is observed when peoples subjective confidence in their own ability is greater than their objective (actual) performance',
        why_it_happens:
          'When a persons subjective confidence in his or her judgments is reliably greater than the objective accuracy of those judgments',
        how_to_avoid_it: 'Challenge your beliefs',
        related_biases: 'Illusion of control, Planning fallacy',
        read_more: 'https://en.wikipedia.org/wiki/Overconfidence_effect',
      },
      'Outcome bias': {
        short_description:
          'Outcome bias arises when a decision is based on the outcome of previous events, without regard to how the past events developed.',
        why_it_happens:
          'The reason why an individual makes this mistake is that he or she will incorporate currently available information when evaluating a past decision. ',
        how_to_avoid_it:
          'To avoid the influence of outcome bias, one should evaluate a decision by ignoring information collected after the fact and focusing on what the right answer is, or was at the time the decision was made.',
        related_biases: 'Historians fallacy',
        read_more: 'https://en.wikipedia.org/wiki/Outcome_bias',
      },
      'Fundamental attribution error': {
        short_description:
          'The fundamental attribution error (FAE) describes how, when making judgments about people’s behavior, we often overemphasize dispositional factors and downplay situational ones',
        why_it_happens:
          'On an intellectual level, we all understand that people’s behavior is shaped by the situations they find themselves in. Very few people would try to argue that everybody behaves in exactly the same way, regardless of the circumstances. The problem is not that we lack situational theory (i.e. awareness of the power of the situation). Rather, the FAE comes up when we fail to apply this understanding properly',
        how_to_avoid_it: 'Put yourself in the other person’s shoes',
        related_biases: 'Salience Bias',
        read_more:
          'https://thedecisionlab.com/biases/fundamental-attribution-error/',
      },
      'Status quo bias': {
        short_description:
          'The status quo biasdescribes our preference for the current state of affairs; resulting in resistance to change',
        why_it_happens:
          'Social psychology has offered up several explanations as to why status quo bias occurs. It is consistent with the concepts of loss aversion and regret avoidance, both of which have been shown to influence decision-making.Once you’re able to recognize that you’re engaging in status quo bias, you’re able to work towards avoiding in. Part of this is simply taking the time to weigh all of your options carefully, giving them each equal consideration.',
        related_biases: 'Anchoring Bias, Loss aversion',
        read_more: 'https://thedecisionlab.com/biases/status-quo-bias/',
      },
      'Authority bias': {
        short_description:
          'Authority bias is the tendency to attribute greater accuracy to the opinion of an authority figure (unrelated to its content) and be more influenced by that opinion',
        why_it_happens:
          'Humans generally have a deep-seated duty to authority and tend to comply when requested by an authority figure',
        how_to_avoid_it:
          'Think logically. Whenever you have to follow any instruction, think with an open mind if you are doing the right thing',
        related_biases: 'Groupthink, Confirmation Bias',
        read_more: 'https://en.wikipedia.org/wiki/Authority_bias',
      },
      'False consensus effect': {
        short_description:
          'The false-consensus effect refers to peoples tendency to assume that others share their beliefs and will behave similarly in a given context',
        why_it_happens:
          'It can be derived from a desire to conform and be liked by others in a social environment.',
        how_to_avoid_it: 'Challenge your beliefs',
        related_biases: 'Illusion of control, Planning fallacy',
        read_more: 'https://en.wikipedia.org/wiki/False_consensus_effect',
      },
      'Proportionality Bias': {
        short_description:
          'The proportionality bias is the tendency to assume that big events have big causes. It is a type of cognitive bias and plays an important role in peoples tendency to accept conspiracy theories.',
        why_it_happens:
          'When something big happens, we tend to assume that something big must have caused it',
        how_to_avoid_it:
          'It’s possible to have theories about small things, that may have already been confirmed or falsified. Do remember to check once. Or twice. Thrice if you need to.',
        related_biases: '',
        read_more: 'https://en.wikipedia.org/wiki/Proportionality_bias',
      },
    },
    metrics: [],
    feedbacks: [],
    sentences: [],
    writtenFeedback: [],
    writtenDiaryRecord: [],
  },
  mutations: {
    addTokens: (state, tokens) => {
      state.tokens = tokens
    },
    addTokenPredictions: (state, tokenPredictions) => {
      state.tokenPredictions = tokenPredictions
    },
    addFeedback: (state, feedback) => {
      state.feedbacks.push(feedback)
    },
    addWrittenFeedback: (state, feedback) => {
      state.writtenFeedback.push(feedback)
    },
    addDiaryRecord: (state, diaryRecord) => {
      state.writtenDiaryRecord.push(diaryRecord)
    },
    addValidSentence: (state, sent) => {
      state.sentences.push(sent)
    },
  },
}
