import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueScrollactive from 'vue-scrollactive'

import VueGtag from 'vue-gtag'
import aos from 'aos'
import router from './router'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import vuetify from './plugins/vuetify'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/tour'
// import '@/assets/scss/main.scss'
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Google Analytics plugin
Vue.use(VueGtag, {
  config: { id: 'G-S9RQDBFQBX' },
})

// Scrollactive
Vue.use(VueScrollactive)

Vue.config.productionTip = false

new Vue({
  created() {
    aos.init()
  },
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
