import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        pageTitle: 'My Hue',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/app',
      name: 'app',
      component: () => import('@/views/Application.vue'),
      meta: {
        pageTitle: 'App',
      },
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import('@/views/Feedback.vue'),
      meta: {
        pageTitle: 'Feedback',
        breadcrumb: [
          {
            text: 'Feedback',
            active: true,
          },
        ],
      },
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/About.vue'),
      meta: {
        pageTitle: 'About Us',
        breadcrumb: [
          {
            text: 'About Us',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('@/views/Support.vue'),
      meta: {
        pageTitle: 'Support',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/Contact.vue'),
      meta: {
        pageTitle: 'Contact',
        breadcrumb: [
          {
            text: 'Contact',
            active: true,
          },
        ],
      },
    },
    {
      path: '/diary',
      name: 'diary',
      component: () => import('@/views/Diary.vue'),
      meta: {
        pageTitle: 'Diary',
        breadcrumb: [
          {
            text: 'Diary',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tos',
      name: 'tos',
      component: () => import('@/views/TermsOfService.vue'),
      meta: {
        pageTitle: 'Term Of Service',
        breadcrumb: [
          {
            text: 'Term Of Service',
            active: true,
          },
        ],
      },
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/Privacy.vue'),
      meta: {
        pageTitle: 'Privacy Policy',
        breadcrumb: [
          {
            text: 'Privacy Policy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscription',
      name: 'subscription',
      component: () => import('@/views/Subscription.vue'),
      meta: {
        pageTitle: 'Subscription',
        breadcrumb: [
          {
            text: 'Subscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/blog-details',
      name: 'blog',
      component: () => import('@/views/blog/BlogDetails.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
